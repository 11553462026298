import Vue from 'vue';

export function getLogEvent(params) {
  return Vue.$http({
    url: 'logger/getLogEvent',
    method: 'get',
    params,
  });
}

export function getLogGroup(params) {
  return Vue.$http({
    url: 'logger/getLogGroup',
    method: 'get',
    params,
  });
}

export function listLogEvents(params) {
  return Vue.$http({
    url: 'logger/listLogEvents',
    method: 'get',
    params,
  });
}

export function listLogGroups(params) {
  return Vue.$http({
    url: 'logger/listLogGroups',
    method: 'get',
    params,
  });
}

export function enableDebug(data) {
  return Vue.$http({
    url: 'logger/enableDebug',
    method: 'post',
    data,
  });
}

export function disableDebug(data) {
  return Vue.$http({
    url: 'logger/disableDebug',
    method: 'post',
    data,
  });
}

export function getProjects() {
  return [
    'site-backend',
    'admin-backend',
  ];
}
