<template>
  <div class="logger-view">
    <div
      v-loading="logGroupLoading"
      class="logger-view-header"
    >
      <div class="truncate-text">
        <el-tooltip
          :content="'Log level: ' + (logGroup && logGroup.level)"
          placement="right"
        >
          <span
            class="logger-level"
            :class="'logger-level__' + (logGroup && logGroup.level)"
          ></span>
        </el-tooltip>
        <span class="logger-view-title">
          <el-tag
            v-if="logGroup && logGroup.data && logGroup.data.isFatal"
            size="mini"
            type="danger"
            effect="plain"
          >
            Fatal
          </el-tag>
          {{ logGroup && logGroup.title }}
        </span>
      </div>

      <div class="logger-view-info">
        <i class="fal fa-server"></i>
        <span class="logger-project">{{ logGroup && logGroup.project }}</span>
        <i class="fal fa-clock"></i>
        <span class="logger-time">{{ logGroup && logGroup.updatedAt | timeAgo }} ago — {{ logGroup && logGroup.createdAt | timeAgo }} old</span>
      </div>

      <el-tabs
        v-model="activeSection"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          label="Details"
          name="details"
        />
        <el-tab-pane
          label="Events"
          name="events"
        />
      </el-tabs>
    </div>

    <div
      v-loading="logEventLoading"
      class="app-container"
    >
      <template v-if="activeSection === 'details'">
        <div class="logger-event-info">
          <div>
            <div class="logger-event-uid">
              <span>Event</span> {{ logEvent && logEvent.logEventUid }}
            </div>
            <div>{{ logEvent && logEvent.createdAt | formatDate }}</div>
            <div
              v-if="logEvent.rayId"
              class="logger-event-ray-id"
            >
              <br />
              <span>Ray ID</span>
              <el-input
                type="text"
                :value="logEvent.rayId"
                readonly
              />
            </div>
          </div>

          <div>
            <el-button-group>
              <el-button
                :disabled="!(logEvent && logEvent.previousEventUid)"
                size="small"
                plain
                @click="getPrevEvent"
              >
                <i class="fal fa-arrow-alt-left"></i> Older
              </el-button>
              <el-button
                :disabled="!(logEvent && logEvent.nextEventUid)"
                size="small"
                plain
                @click="getNextEvent"
              >
                Newer <i class="fal fa-arrow-alt-right"></i>
              </el-button>
            </el-button-group>
          </div>
        </div>

        <div class="logger-pills">
          <div
            v-if="logEvent && logEvent.hostName"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              hostname
            </div>
            <div class="logger-pill-value">
              {{ logEvent && logEvent.hostName }}
            </div>
          </div>

          <div
            v-if="logEvent && logEvent.envName"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              environment
            </div>
            <div class="logger-pill-value">
              {{ logEvent && logEvent.envName }}
            </div>
          </div>

          <div
            v-if="logEvent && logEvent.processName"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              process
            </div>
            <div class="logger-pill-value">
              {{ logEvent && logEvent.processName }}
            </div>
          </div>

          <div
            v-if="logEvent && logEvent.release"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              release
            </div>
            <div class="logger-pill-value">
              {{ logEvent && logEvent.release }}
            </div>
          </div>

          <div
            v-if="logEvent && logEvent.level"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              level
            </div>
            <div class="logger-pill-value">
              {{ logEvent && logEvent.level }}
            </div>
          </div>

          <div
            v-if="logGroup && logGroup.count"
            class="logger-pill"
          >
            <div class="logger-pill-name">
              count
            </div>
            <div class="logger-pill-value">
              {{ logGroup && logGroup.count }}
            </div>
          </div>
        </div>

        <template v-if="logEvent && logEvent.type === 'log'">
          <el-divider />

          <div class="logger-section-title">
            Message
          </div>

          <div class="logger-extra-value">
            <div v-if="typeof logMessage === 'string'">
              <pre>{{ logMessage }}</pre>
            </div>

            <json-viewer
              v-else
              :value="logMessage"
              :expand-depth="8"
              sort
            />
          </div>
        </template>

        <template v-if="logEvent && logEvent.type === 'error'">
          <el-divider />

          <div class="logger-section-title">
            <div>Exception</div>
            <div>
              <el-radio-group
                v-model="errorFormat"
                size="mini"
              >
                <el-radio-button label="formatted">
                  Formatted
                </el-radio-button>
                <el-radio-button label="raw">
                  Raw
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <template v-if="errorFormat === 'formatted'">
            <div class="logger-exception-name">
              {{ logEvent && logEvent.data && logEvent.data.name }}
            </div>

            <div class="logger-exception-message">
              <pre>{{ logEvent && logEvent.data && logEvent.data.message }}</pre>
            </div>

            <template v-if="logEvent && logEvent.data && logEvent.data.stack">
              <ul
                class="logger-exception-list"
              >
                <li
                  v-for="(item, index) in filteredStack"
                  :key="index"
                  class="logger-exception-item"
                >
                  <span class="logger-exception-filename">{{ item.fileName }}</span>
                  <template v-if="item.functionName">
                    in <span class="logger-exception-function">{{ item.functionName }}</span>
                  </template>
                  at line <span class="logger-exception-lineno">{{ item.lineNumber }}:{{ item.columnNumber }}</span>
                </li>
              </ul>
            </template>

            <template v-if="logEvent && logEvent.data && logEvent.data.meta">
              <br />
              <div class="logger-exception-name">
                Meta Data
              </div>
              <json-viewer
                :value="logEvent.data.meta"
                :expand-depth="8"
                sort
              />
            </template>
          </template>

          <template v-if="errorFormat === 'raw'">
            <div class="logger-raw-error">
              <pre>{{ logEvent && logEvent.data && logEvent.data.stackRaw }}</pre>
            </div>
          </template>
        </template>

        <template v-if="logEvent.extra">
          <div
            v-for="(value, key) in logEvent.extra"
            :key="key"
          >
            <el-divider />

            <div class="logger-section-title">
              {{ key }}
            </div>

            <div class="logger-extra-value">
              <div v-if="typeof value === 'string'">
                <pre>{{ value }}</pre>
              </div>

              <json-viewer
                v-else
                :value="value"
                :expand-depth="8"
                sort
              />
            </div>
          </div>
        </template>
      </template>

      <template v-if="activeSection === 'events'">
        <el-table
          v-loading="eventListLoading"
          :data="eventList"
          border
        >
          <el-table-column
            label="Date"
            data-label="Date"
            prop="createdAt"
          >
            <template slot-scope="scope">
              <div
                class="link-type"
                @click.stop="viewEvent(scope.row.logEventUid, $event)"
              >
                {{ scope.row.createdAt | formatDate }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="Hostname"
            data-label="Hostname"
            prop="hostName"
          >
            <template slot-scope="scope">
              {{ scope.row.hostName }}
            </template>
          </el-table-column>

          <el-table-column
            label="Environment"
            data-label="Environment"
            prop="envName"
          >
            <template slot-scope="scope">
              {{ scope.row.envName }}
            </template>
          </el-table-column>

          <el-table-column
            label="Release"
            data-label="Release"
            prop="release"
          >
            <template slot-scope="scope">
              {{ scope.row.release }}
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          v-show="eventListTotal > 0"
          :pager-count="5"
          class="pagination"
          background
          layout="total, prev, pager, next, sizes"
          :current-page.sync="eventListQuery.pageNumber"
          :page-size.sync="eventListQuery.pageSize"
          :total="eventListTotal"
          @size-change="getEventList"
          @current-change="getEventList"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { getLogGroup, getLogEvent, listLogEvents } from '@/api/logger';

export default {
  name: 'ViewLog',
  data() {
    return {
      activeSection: 'details',
      errorFormat: 'formatted',
      logGroupLoading: false,
      logEventLoading: false,
      eventListLoading: false,
      logGroupHash: null,
      logEventUid: null,
      logGroup: {},
      logEvent: {},
      eventListTotal: 0,
      eventList: [],
      eventListQuery: {
        pageNumber: 1,
        pageSize: 50,
        sortBy: 'createdAt',
        sortDir: 'desc',
      },
    };
  },
  computed: {
    filteredStack() {
      if (!this.logGroup?.data || !this.logGroup?.data?.stack) {
        return [];
      }
      return this.logGroup?.data?.stack?.filter((item) => item.fileName);
    },
    logMessage() {
      if (this.logEvent?.type === 'log') {
        const { data } = this.logEvent;
        if (Array.isArray(data) && data.length === 1) {
          return data[0];
        }
        return data;
      }
      return null;
    },
  },
  async created() {
    this.logGroupHash = this.$route.params && this.$route.params.logGroupHash;
    this.logEventUid = this.$route.params && this.$route.params.logEventUid;

    await this.getLogGroup(this.logGroupHash);

    if (!this.logEventUid) {
      this.logEventUid = this.logGroup?.lastEventUid;
    }

    if (this.logEventUid) {
      await this.getLogEvent(this.logEventUid);
    }
  },
  methods: {
    async getLogGroup(logGroupHash) {
      try {
        this.logEventLoading = true;
        this.logGroup = {};

        const data = await getLogGroup({ logGroupHash });
        this.logGroup = data.logGroup;

        this.logEventLoading = false;
      } catch (e) {
        this.logEventLoading = false;

        await this.$router.push({
          name: 'ListGroups',
        });
      }
    },
    async getLogEvent(logEventUid) {
      try {
        this.loading = true;
        this.logEvent = {};

        const data = await getLogEvent({ logEventUid });
        this.logEvent = data.logEvent;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        await this.$router.push({
          name: 'ListGroups',
        });
      }
    },
    async getEventList(refresh = false) {
      try {
        this.eventListLoading = true;

        if (!refresh) {
          this.list = [];
          this.eventListQuery.pageNumber = 1;
        }

        this.eventListQuery.filter = JSON.stringify({
          logGroupHash: this.logGroupHash,
        });

        const data = await listLogEvents(this.eventListQuery);
        const { items, pageInfo } = data.page;
        this.eventList = items;
        this.eventListTotal = pageInfo.itemsAvailable;
        this.eventListLoading = false;
      } catch (e) {
        this.eventListLoading = false;
      }
    },
    async getPrevEvent() {
      if (this.logEvent.previousEventUid) {
        await this.$router.push({
          name: 'ViewLog',
          params: {
            logGroupHash: this.logGroupHash,
            logEventUid: this.logEvent.previousEventUid,
          },
        });
      }
    },
    async getNextEvent() {
      if (this.logEvent.nextEventUid) {
        await this.$router.push({
          name: 'ViewLog',
          params: {
            logGroupHash: this.logGroupHash,
            logEventUid: this.logEvent.nextEventUid,
          },
        });
      }
    },
    async viewEvent(logEventUid) {
      if (logEventUid === this.logEventUid) {
        this.activeSection = 'details';
        return;
      }

      await this.$router.push({
        name: 'ViewLog',
        params: {
          logGroupHash: this.logGroupHash,
          logEventUid,
        },
      });
    },
    async handleTabClick() {
      if (this.activeSection === 'events') {
        await this.getEventList();
      }
    },
  },
};

</script>

<style lang="scss">
  @import '../../styles/views/_logger.scss';
</style>
